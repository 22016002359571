<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Two Means"
            right-nav="home"
        />
        <div class="m-view d-flex py-3">
            <h2>H<sub>A</sub> :  μ<sub>1</sub> </h2>
            <select
                v-model="selected.value"
                class="form-select-lg mx-3"
            >
                <option
                    v-for="(option, key) in options"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <h2>μ<sub>2</sub></h2>
        </div>

        <b-list-group
            class="btn-group-toggle btn-group-vertical"
            data-toggle="buttons"
        >
            <label
                v-for="(picker, key) in pickers.type"
                :key="key"
                class="btn btn-outline-secondary p-3"
                :class="pick.type === key ? ' active': ''"
            >
                <input
                    v-model="pick.type"
                    type="radio"
                    class="btn-group-toggle"
                    :value="key"
                >
                {{ picker }}
            </label>
            <br>
            <label
                v-for="(picker, key) in pickers.sample"
                v-show="toggleSample"
                :key="key"
                class="btn btn-outline-secondary p-3"
                :class="pick.sample === key ? ' active': ''"
            >
                <input
                    v-model="pick.sample"
                    type="radio"
                    class="btn-group-toggle"
                    :value="key"
                >
                {{ picker }}
            </label>
            <br>
            <label
                v-for="(picker, key) in pickers.variance"
                v-show="toggleVariance"
                :key="key"
                class="btn btn-outline-secondary p-3"
                :class="pick.variance === key ? ' active': ''"
            >
                <input
                    v-model="pick.variance"
                    type="radio"
                    class="btn-group-toggle"
                    :value="key"
                >
                {{ picker }}
            </label>
        </b-list-group>

        <div class="sticky-bottom m-3 mt-5">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Done
            </button>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'TwoMeanChoice',
    components: {
        TopNavigationBar,
    },
    data () {
        return {
            computeType: 'hypothesis',
            options: [
                { value: '<' },
                { value: '>' },
                { value: '≠' },
            ],
            selected: { value: '>' },
            pickers: {
                type: {
                    ind: 'INDEPENDENT',
                    dep: 'DEPENDENT',
                },
                sample: {
                    large: 'LARGE SAMPLES',
                    small: 'SMALL SAMPLES',
                },
                variance: {
                    notEq: 'ASSUME ≠ VARIANCES',
                    eq: 'ASSUME = VARIANCES',
                },
            },
            pick: {
                type: 'ind',
                sample: 'large',
                variance: 'notEq',
            },
        }
    },
    computed: {
        toggleVariance () {
            var { type, sample } = this.pick
            return _.isEqual([type, sample], ['ind', 'small'])
        },
        toggleSample () {
            var { type } = this.pick
            return _.isEqual([type], ['ind'])
        },
        computationPicked () {
            var { type, sample } = this.pick
            var pickValsAll = _.valuesIn(this.pick)
            var computationPicked

            switch (true) {
            case _.isEqual(pickValsAll, ['ind', 'small', 'eq']):
                computationPicked = 'TwoISmallEqual'
                break
            case _.isEqual(pickValsAll, ['ind', 'small', 'notEq']):
                computationPicked = 'TwoISmallNotEqual'
                break
            case _.isEqual([type, sample], ['ind', 'large']):
                computationPicked = 'TwoILarge'
                break
            case _.isEqual([type], ['dep']):
                computationPicked = 'TwoDLarge'
                break
            default:
                computationPicked = ''
            }
            return computationPicked
        },
    },
    mounted () {
        var store = localStore.getStore('hypothesis-data-choice').choice
        if (store) {
            this.pick = store.pick
            this.selected = store.selected
        }
    },
    methods: {
        onSubmit () {
            var { computeType, selected, pick } = this
            const computationPicked = this.computationPicked
            var choice = { computeType, computationPicked, selected, pick }
            localStore.setStoreItem('hypothesis-data-choice', { choice })
            this.$router.push({ name: 'hypothesis/two-mean-input' })
        },
    },
}
</script>
